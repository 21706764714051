import React from 'react';
import '../styles/download.css';
import activity1 from '../images/activity1.png';
import activity2 from '../images/activity2.png';
import activity3 from '../images/activity3.png';
import activity4 from '../images/activity4.png';
import activity5 from '../images/activity5.png';
import activity6 from '../images/activity6.png';
import activity7 from '../images/activity7.png';
const Banner = ({ speed = 25000 }) => {
  const images = [
    activity1,
    activity2,
    activity3,
    activity4,
    activity5,
    activity6,
    activity7,
  ].map((image) => ({
    image,
  }));
  return (
    <div className="inner">
      <div className="wrapper">
        <section className="section" style={{ '--speed': `${speed}ms` }}>
          {images.map(({ image }, index) => (
            <div className="image" key={index}>
              <img src={image} alt={'image'} />
            </div>
          ))}
        </section>
        <section className="section" style={{ '--speed': `${speed}ms` }}>
          {images.map(({ image }, index) => (
            <div className="image" key={index}>
              <img src={image} alt={'image'} />
            </div>
          ))}
        </section>
        <section className="section" style={{ '--speed': `${speed}ms` }}>
          {images.map(({ image }, index) => (
            <div className="image" key={index}>
              <img src={image} alt={'image'} />
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export { Banner };
