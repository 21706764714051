import React from 'react';
import { Helmet } from 'react-helmet';

import { Banner } from '../../components/Banner';
import Layout from '../../components/Layout';
import appStore from '../../images/app-store.svg';
import belowImage from '../../images/below.svg';
import googleStore from '../../images/google-play.svg';
import upperImage from '../../images/upper.svg';

const Page = ({ location }) => {
  return (
    <Layout location={location}>
      <Helmet>
        <title>Racqy - Download - iOS & Android</title>
      </Helmet>

      <main className="main">
        <section className="heroSection">
          <div className="leftSide">
            <div>
              <span className="title-web">
                <h1>Discover new adventures.</h1>
              </span>
              <span className="description-web">
                <p>
                  Explore activities, hobbies and interests, and <br />
                  find friends to join you on your next adventure.
                </p>
              </span>

              <span className="description-download">
                <p>
                  Join your friends and explore activities, hobbies, and
                  interests for your next adventure.
                </p>
              </span>

              <div className="buttonContainer">
                <a href="https://apps.apple.com/se/app/racqy/id1609593141?l=en">
                  <img src={appStore} />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.racqy.racqy">
                  <img src={googleStore} />
                </a>
              </div>
            </div>
          </div>

          <div className="rightSide">
            <img
              src={upperImage}
              style={{
                zIndex: 1,
                position: 'absolute',
                top: 10,

                transform: 'translate(0%,-44%)',
                left: 0,
                // right: -10,

                width: '100%',
              }}
            />

            <Banner speed={53000} />

            <img
              src={belowImage}
              style={{
                zIndex: 1,
                position: 'absolute',
                bottom: -5,
                width: '100%',
                transform: 'translate(0%,55%)',
                left: 0,
              }}
            />
          </div>

          <div className="heading-m">
            <h1>Discover new adventures.</h1>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Page;
